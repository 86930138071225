import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Rankings.css';
import { FaCrown, FaSearch } from 'react-icons/fa';

interface Character {
  name: string;
  level: number;
  job: number;
  exp: number;
  gender: number;
  hair: number;
  face: number;
  skin: number;
}

const jobNames: { [key: number]: string } = {
  0: 'Beginner',
  100: 'Warrior',
  110: 'Fighter',
  111: 'Crusader',
  112: 'Hero',
  120: 'Page',
  121: 'White Knight',
  122: 'Paladin',
  130: 'Spearman',
  131: 'Dragon Knight',
  132: 'Dark Knight',
  200: 'Magician',
  210: 'FP Wizard',
  211: 'FP Mage',
  212: 'FP Archmage',
  220: 'IL Wizard',
  221: 'IL Mage',
  222: 'IL Archmage',
  230: 'Cleric',
  231: 'Priest',
  232: 'Bishop',
  300: 'Bowman',
  310: 'Hunter',
  311: 'Ranger',
  312: 'Bowmaster',
  320: 'Crossbowman',
  321: 'Sniper',
  322: 'Crossbow Master',
  400: 'Thief',
  410: 'Assassin',
  411: 'Hermit',
  412: 'Night Lord',
  420: 'Bandit',
  421: 'Chief Bandit',
  422: 'Shadower',
  500: 'Pirate',
  510: 'Brawler',
  511: 'Marauder',
  512: 'Buccaneer',
  520: 'Gunslinger',
  521: 'Outlaw',
  522: 'Corsair',
  800: 'Maple Leaf Brigadier',
  900: 'GM',
  910: 'Super GM',
  1000: 'Noblesse',
  1100: 'Dawn Warrior 1',
  1110: 'Dawn Warrior 2',
  1111: 'Dawn Warrior 3',
  1112: 'Dawn Warrior 4',
  1200: 'Blaze Wizard 1',
  1210: 'Blaze Wizard 2',
  1211: 'Blaze Wizard 3',
  1212: 'Blaze Wizard 4',
  1300: 'Wind Archer 1',
  1310: 'Wind Archer 2',
  1311: 'Wind Archer 3',
  1312: 'Wind Archer 4',
  1400: 'Night Walker 1',
  1410: 'Night Walker 2',
  1411: 'Night Walker 3',
  1412: 'Night Walker 4',
  1500: 'Thunder Breaker 1',
  1510: 'Thunder Breaker 2',
  1511: 'Thunder Breaker 3',
  1512: 'Thunder Breaker 4',
  2000: 'Legend',
  2100: 'Aran 2',
  2110: 'Aran 3',
  2111: 'Aran 4',
  2112: 'Aran 5'
};

const getJobName = (jobId: number): string => {
  if (jobNames[jobId]) {
    return jobNames[jobId];
  }
  
  const baseJobId = Math.floor(jobId / 100) * 100;
  for (let i = jobId; i >= baseJobId; i--) {
    if (jobNames[i]) {
      return jobNames[i];
    }
  }
  
  return 'Unknown';
};

const excludedCharacters = ['Phil', 'BannedUser1', 'BannedUser2'];

const Rankings: React.FC = () => {
  const [characters, setCharacters] = useState<Character[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Character; direction: 'ascending' | 'descending' }>({ key: 'level', direction: 'descending' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const charactersResponse = await axios.get<Character[]>('/api/rankings');
        const filteredCharacters = charactersResponse.data.filter(
          char => !excludedCharacters.includes(char.name)
        );
        setCharacters(filteredCharacters);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortedCharacters = React.useMemo(() => {
    let sortableCharacters = [...characters];
    if (searchTerm) {
      sortableCharacters = sortableCharacters.filter(char => 
        char.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        getJobName(char.job).toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    sortableCharacters.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortableCharacters;
  }, [characters, searchTerm, sortConfig]);

  const requestSort = (key: keyof Character) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  if (loading) return <div className="loading">Loading rankings...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="rankings-container">
      <h2 className="rankings-title">Character Rankings</h2>
      <div className="search-bar">
        <FaSearch />
        <input
          type="text"
          placeholder="Search by name or job..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className="rankings-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th onClick={() => requestSort('name')}>Name</th>
            <th onClick={() => requestSort('level')}>Level</th>
            <th onClick={() => requestSort('job')}>Job</th>
            <th onClick={() => requestSort('exp')}>EXP</th>
          </tr>
        </thead>
        <tbody>
          {sortedCharacters.map((char, index) => (
            <tr key={char.name} className={index < 3 ? `top-${index + 1}` : ''}>
              <td>{index === 0 ? <FaCrown className="crown-icon" /> : index + 1}</td>
              <td>{char.name}</td>
              <td>{char.level}</td>
              <td>{getJobName(char.job)}</td>
              <td>{char.exp.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Rankings;