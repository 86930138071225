import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import SignUp from "./components/SignUp";
import Rankings from "./components/Rankings";
import Home from "./components/Home";
import Download from "./components/Download";
import { FaBars, FaTimes } from "react-icons/fa";
import "./App.css";
import logo from "./components/logo.png";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="game-header">
          <div className="header-content">
            <Link to="/" className="logo-link">
              <img src={logo} alt="Legacy Logo" className="header-logo" />
            </Link>
            <div className="menu-toggle" onClick={toggleMenu}>
              {menuOpen ? <FaTimes /> : <FaBars />}
            </div>
            <nav className={menuOpen ? "open" : ""}>
              <ul>
                <li>
                  <Link to="/" onClick={toggleMenu}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/signup" onClick={toggleMenu}>
                    Sign Up
                  </Link>
                </li>
                <li>
                  <Link to="/rankings" onClick={toggleMenu}>
                    Rankings
                  </Link>
                </li>
                <li>
                  <Link to="/download" onClick={toggleMenu}>
                    Download
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>

        <main className="game-main">
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/rankings" element={<Rankings />} />
            <Route path="/download" element={<Download />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </main>

        <footer className="game-footer">
          <p>&copy; 2024 Legacy - Relive the Adventure!</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
