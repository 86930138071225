import React from 'react';
import './Home.css';
import legacyImage from './legacy.png';

const Home: React.FC = () => {
  return (
    <div className="home">
      <header className="game-hero">
        <img src={legacyImage} alt="Legacy Logo" className="game-logo" />
        <h1 className="game-title">Welcome to Legacy</h1>
        <p className="game-tagline">Experience the nostalgia of classic MapleStory v83!</p>
        <div className="game-cta">
          <a href="https://discord.gg/j4pt8dwp4s" target="_blank" rel="noopener noreferrer" className="game-button discord-button">
            Join our Discord
          </a>
          <a href="download" className="game-button download-button">
            Download Now
          </a>
        </div>
        <p className="game-safety-notice">No virus detection, the game is safe. Keep your antivirus on!</p>
      </header>
      
      <section className="game-features">
        <h2 className="section-title">Game Features</h2>
        <div className="game-feature-grid">
          <div className="game-feature-item">
            <h3>Rates</h3>
            <ul>
              <li>5x EXP/Quest</li>
              <li>2x Drop</li>
              <li>3x Meso</li>
            </ul>
          </div>
          <div className="game-feature-item">
            <h3>Important Features</h3>
            <ul>
              <li><strong>No HP Washing</strong></li>
              <li><strong>Increased mob respawn rate</strong></li>
              <li><strong>HD Client</strong></li>
              <li>Custom Game Launcher</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;