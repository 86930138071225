import React, { useState, FormEvent } from 'react';
import axios, { AxiosError } from 'axios';
import './SignUp.css';
import { FaUser, FaLock, FaEnvelope, FaBirthdayCake } from 'react-icons/fa';

interface FormData {
  name: string;
  password: string;
  email: string;
  birthday: string;
}

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    password: '',
    email: '',
    birthday: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const sanitizeInput = (input: string): string => {
    return input.replace(/[&<>"']/g, (match) => {
      const escape: { [key: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
      };
      return escape[match];
    });
  };

  const validateForm = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      setError('Please enter a valid email address.');
      return false;
    }

    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters long.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    if (!validateForm()) {
      return;
    }

    const sanitizedData = {
      name: sanitizeInput(formData.name),
      password: formData.password,
      email: sanitizeInput(formData.email),
      birthday: sanitizeInput(formData.birthday),
    };

    setLoading(true);
    try {
      const response = await axios.post('/api/signup', sanitizedData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);
      setSuccess(true);
      setFormData({ name: '', password: '', email: '', birthday: '' });
    } catch (error) {
      console.error('Sign up error:', error);
      if (axios.isAxiosError(error)) {
        setError(`Sign up failed: ${(error as AxiosError<{message: string}>).response?.data?.message || 'An error occurred'}`);
      } else {
        setError('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <h2 className="signup-title">Sign Up for Legacy</h2>
      {success && <div className="success-message">Sign up successful! Welcome to Legacy!</div>}
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="signup-form">
        <div className="form-group">
          <label htmlFor="name"><FaUser /> Username:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            maxLength={50}
            pattern="[A-Za-z0-9_]+"
            title="Username can only contain letters, numbers, and underscores"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password"><FaLock /> Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            minLength={6}
            maxLength={12}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email"><FaEnvelope /> Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="birthday"><FaBirthdayCake /> Birthday:</label>
          <input
            type="date"
            id="birthday"
            name="birthday"
            value={formData.birthday}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
    </div>
  );
};

export default SignUp;