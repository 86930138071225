// Download.tsx
import React from 'react';
import './Download.css';
import { FaDownload, FaGoogleDrive, FaDiscord } from 'react-icons/fa';

const Download: React.FC = () => {
  return (
    <div className="download-container">
      <h2 className="download-title">Download Legacy Launcher</h2>
      <div className="download-options">
        <div className="download-option">
          <h3><FaDownload /> Direct Download</h3>
          <a href="/download/Launcher.rar" download className="download-button">
            Download Launcher
          </a>
        </div>
        <div className="download-option">
          <h3><FaGoogleDrive /> Google Drive Download</h3>
          <a 
            href="https://drive.google.com/file/d/1ZCNwH4omWr70VlGDiWQbufbSu7IkZR-g/view?usp=sharing" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="download-button"
          >
            Download from Google Drive
          </a>
        </div>
      </div>
      <div className="download-info">
        <p>Choose your preferred download method. The launcher is essential to play Legacy.</p>
        <p>The Launcher is included in the game files of the Google Drive link.</p>
        <p>If you encounter any issues, please join our <a href="https://discord.gg/your-discord-link" target="_blank" rel="noopener noreferrer" className="discord-link"><FaDiscord /> Discord</a>.</p>
      </div>
    </div>
  );
};

export default Download;